
.contactContainer {
    background-color: #d8b9ac;
    display: flex;
    justify-content: space-between;
    margin: 0;
}

.contactInfo {
    display: flex;
    flex-direction: column;
    width: 50%;
    text-align: center;
    margin: 5vw 10vw;
    justify-content: center;
    align-items: center;
}

.contactImage {
    width: 40vw;
}

.contactImage img {
    width: 100%;
    height: 100%;
}

.contactHeader {
    color: #f6ece8;
    margin-bottom: 3vw;
}

.textArea {
    background-color: white;
    color: black;
    border: none;
    padding: 10px;
    margin: 2vw 0;
    width: 80%;
    height: 30%;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    font-family: Andada;
    font-size: 1.2vw;
}

.inputField, .submitButton {
    background-color: #f6ece8;
    color: black;
    border: none;
    padding: 1.1vw;
    margin: 1vw 0;
    width: 80%;
    border-radius: 30px;
    font-size: 1.2vw;
    text-align: left;
    font-family: Andada;
}

.submitButton {
    width: 30%;
    text-align: center;
    color: #6f3f2d;
    font-size: 1.4vw;
    padding: 0.9vw 0;
}

.sendMail {
    font-size: 2.4vw;
    color: #fff;
    font-family: Andada;
}


@media (max-width: 768px) {
    .contactContainer {
        flex-direction: column-reverse;
        align-items: center;
    }

    .contactImage {
        width: 80%; 
    }

    .contactInfo {
        width: 90%;
        text-align: center;
    }

    .inputField, .submitButton {
        padding: 2.2vw;
        margin: 1.5vw 0;
        font-family: Andada;
        font-size: 3.7vw;
    }

    .submitButton {
        width: 40%;
        text-align: center;
        color: #6f3f2d;
        font-size: 3.4vw;
    }

    .contactHeader {
        font-size: 9vw;
        margin: 3vw 0 5vw 0;
    }

    .textArea {
        font-size: 3.7vw;
    }
}



