/* Offer.module.css */
.offerTitle {
  margin-left: 3vw;
  padding: 40px 40px 0 40px;
  color: #6f3f2d;
  background-color: #f6ece8;
  font-family: Andada;
  font-weight: lighter;
}

.offerContainer {
  background-color: #f6ece8;
  padding: 0 12.5vw 5vw 12.5vw;
  display: flex;
  justify-content: center;
  align-items: center;
  height: auto;
  /* grid-template-columns: repeat(3, 1fr); */
  font-family: Andada;
  position: relative;
}

.whiteCircle {
  display: grid;
  grid-template-columns: repeat(3, 18vw);
  gap: 6vw;
  margin: 2vw 0 6vw 0;
}

.specialImage img {
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 2;
  width: 40%;
  margin-left: 5%;
  width: 20vw;
}


.box {
  position: relative;
  z-index: 1;
  margin-top: 1vw;
  background-color: white;
  border-radius: 50%;
  text-align: center;
  display: flex;
  justify-content: center;
  width: 100%;
  height: 0;
  padding-top: 100%;
}

.box h2 {
  margin-top: 0;
}

h4 {
  color: #6f3f2d;
  font-weight: normal;
  margin: 1vw 0;
}

.specialBackground {
  background-color: #f6ece8;
}

.imgwrapper {
  height: 5vw;
  text-align: center;
}

.icon2 {
  width: 15vw;
  height: auto;
  z-index: 2;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.box p {
  position: absolute;
  color: #6f3f2d;
  top: 80%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.box h4 {
  font-size: 1.4vw;
}



@media (max-width: 768px) {
  .whiteCircle {
    grid-template-columns: repeat(2, 40vw); /* Dwie kolumny na telefonach komórkowych */
    grid-template-rows: repeat(3, auto);
  }

  .specialImage img {
    margin-left: 10vw;
    height: 62vw;
    width: auto;
    /* Ukryj specjalne zdjęcie na telefonach komórkowych */
  }

  .box {
    margin-top: 10vw;
  }

  .box p {
    font-size: 3vw;
  }

  .box h4 {
    font-size: 3.8vw;
  }

  .icon2 {
    width: 35vw;
  }

  .offerContainer {
  padding-bottom: 12vw;
  }

  .offerTitle {
    padding: 15vw 0 0 0;
    text-align: center  ;
  }
}