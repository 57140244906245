.recordsContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: white;
    padding: 20px;
    border: 1px solid #ddd;
}

.content {
    display: flex;
    /* justify-content: space-between; */
    align-items: center;
    width: 100%;
    flex-direction: column;
}

.leftContent {
    display: flex;
}

.buttonsColumn {
    margin-left: 5vw;
    width: 80%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 1.5vw;
}

.button {
    display: flex;
    background-color: #f6ece8;
    color: #6f3f2d;
    padding: 1vw;
    text-align: center;
    cursor: pointer;
    transition: background-color 0.3s;
    border-radius: 30px;
    height: 2.4vw;
    align-items: center;
    justify-content: center;
}

.button:hover {
    background-color: #6f3f2d;
    color: white;
}

.imageColumn {
    width: 40%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 3vw 5vw;
}

img {
    max-width: 100%;
    height: auto;
}

a {
    margin-top: 20px;
    text-decoration: none;
    color: #6f3f2d;
    font-weight: bold;
    transition: color 0.3s;
}

a:hover {
    color: #f6ece8;
}

.title {
    width: 100%;
    margin-left: 9vw;
    margin-bottom: 3vw;
    text-align: left;
    color: #6f3f2d;
}

@media (max-width: 768px) {
  
    .content {
      flex-direction: column;
      align-items: center;
    }

    .leftContent {
      display: block;
  }

    .buttonsColumn {
      margin-left: 0;
      width: 100%;
      font-size: 4vw;
    }
  
    .button {
      width: 40vw;
      height: 10vw;
      margin: 1.5vw;
      padding: 2vw;
    }
  
    .imageColumn {
      width: 100%;
      margin: 0;
      display: flex;
      text-align: center;
    }

    .pianoImage {
      max-width: 75%;
      height: auto;
      margin-top: 10vw;
      margin-bottom: 5vw;
  }
  
    .title {
      text-align: center;
      padding: 3vw 0;
    }
  }
  
  