/* YoutubeModal.module.css */

.modalOverlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.7);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
}

.modalContent {
    background: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
    max-width: 90%;
    text-align: center;
    position: relative;
}

.modalContent iframe {
    width: 60vw;
    height: 30vw; /* Dostosuj wysokość do swoich potrzeb */
    border: none;
}

.closeButton {
    position: absolute;
    top: -40px;
    right: -40px;
    background: hsl(0, 16%, 96%);
    color: black;
    border: none;
    /* border-radius: 50%; */
    /* width: 6vw; */
    /* height: 30px; */
    font-size: 1.4rem;
    cursor: pointer;
}

@media (max-width: 768px) {

    .modalContent iframe {
        width: 90vw;
        height: 50vw; /* Dostosuj wysokość do swoich potrzeb */
        border: none;
    }

    .modalContent {
        background: white;
        padding: 2vw;
        max-width: 90%;
    }

    .closeButton {
        position: absolute;
        top: -40px;
        right: 0;
        background:0;
        /* opacity: 0.6; */
        border: none;
        /* border-radius: 50%; */
        /* width: 6vw; */
        /* height: 30px; */
        font-size: 1.5rem;
        cursor: pointer;
        color: white;
    }

    .modalOverlay {
    align-items: start;
    padding-top: 40vw;
    }
}
