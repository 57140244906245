/* AboutMe.css */
.about-me-container {
    display: flex;
    background-color: #ecebec;
    padding: 20px;
    font-family: Andada;
  }
  
  .about-me-content {
    display: flex;
    /* align-items: center; */
    justify-content: space-between;
    width: 100%;
  }
  
  .textContent {
    flex: 1;
    padding: 20px;
    color: #7b503c;
    margin-left: 3vw;
  }
  
  .image-content {
    flex: 1;
    text-align: center;
    height: 50vw;
  }
  
  .textContent p {
    font-size: 1.4vw;
    color: #6f3f2d;
    line-height: 1.5; 
    margin: 0;
  }

  .textContent span {
    font-size: 1.5vw;
    margin: 0;
    color: #6f3f2d;
    line-height: 1.5; 
  }
  
  .Myimage {
    max-height: 100%;
  }


  
  /* Responsywność dla ekranów na telefony (maksymalna szerokość 768px) */
@media (max-width: 768px) {

  .about-me-container {
    padding: 1vw;
    height: 340vw;
    padding: 1vw;
  }

  .hero-container {
    align-items: center;
    text-align: center;
    position: relative;;
  }

  .about-me-content {
    display: block;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }

  .hero-content {
    max-width: 100%;
  }

  .hero-image {
    max-width: 100%;
    order: 2;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
  }

  .image-button {
    position: relative;
    margin-top: 20px;
    order: 1;
  }

  .heroButton {
    display: inline-block; /* Wyświetl przycisk w tekście na mniejszych ekranach */
    margin-top: 10px;
  }

  .title {
    font-size: 6vw; /* Zmniejsz rozmiar tytułu na ekranach telefonów */
    margin-top: 15vw;
  }

  .subtitle {
    font-size: 1.8vw; /* Zmniejsz rozmiar podtytułu na ekranach telefonów */
  }

  .textContent p {
    font-size: 5vw;
    line-height: 1.5; 
    color: #6f3f2d;
    text-align: justify;
  }

  .textContent span {
    font-size: 5.3vw;
    margin: 0;
    color: #6f3f2d;
    line-height: 1.5; 
  }

  .image-content {
    /* right: 20vw; */

    display: flex;
    justify-content: center;
    /* height: 80vw; */
    position: relative;
  }

  .Myimage {
    /* height: 500%;  */
    max-height: 150vw;
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .textContent {
    padding: 20px 0 0 0;
    margin: 0 3vw;
  }

  .textContent p {
    padding-bottom: 0;
  }

  .textContent h1 {
text-align: center;  
margin: 6vw 0;
}

}
