/* Navigation.module.css */

/* Styl nawigacji na większych ekranach */

.hamburger {
  display: none;
}

.nav-bar {
  background-color: white;
  text-align: center;
  padding: 1vw;
  position: fixed;
  width: 100%;
  height: 3vw;
  z-index: 1000;
  display: flex;
  justify-content: center;
}

.nav-list {
  list-style: none;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: 0;
}

 .nav-list ul {
  display: flex;
  justify-content: center;
  align-items: center;
}

.navItem {
  margin: 0vw 0.7vw;
  /* padding-top: 0.9vw; */
}

.nav-link {
  color: #6f3f2d;
  text-decoration: none;
  font-size: 1.6vw;
  cursor: pointer;
  transition: color 0.3s;
  font-family: Andada;
  font-weight: 300;
  padding: 0.5vw;
}

/* Styl burger menu na mniejszych ekranach */
.nav-toggle {
  display: none; /* Ukryj burger menu na większych ekranach */
}

.icon,
.icon-open {
  width: 9vw;
  height: 1.2vw;
  background-color: #6f3f2d;
  margin: 1.2vw 0;
  transition: all 0.3s;
}

.icon-open {
  transform: rotate(30deg);
}

.iconContainer {
  display: none;
  
}

/* Media query na mniejsze ekrany, gdzie chcemy pokazać burger menu */
@media (max-width: 768px) {

.nav-bar {
  height: 10vw;
  padding: 3vw 0 2vw 5vw;
  display: flex;
  justify-content: space-between;
}

  .nav-toggle {
    display: block; /* Pokaż burger menu na mniejszych ekranach */
  }

  .nav-list {
    display: none;
  }

  .nav-link {
    font-size: 6vw;
  }

  .navItem {
    margin-top: 2vw;
  }

  .nav-list-open {
    list-style: none;
    display: flex;
    flex-direction: column;
    justify-content: left;
    align-items: left;
    text-align: left;
    margin-top: 10vw;
    padding: 6vw 5vw;
    position: absolute;
    left: 0;
    background-color: #fff;
    width: 50%;
    height: 150vw;
    z-index: 1000;
    transition: all 0.3s;
  }

  .hamburger {
    margin: 0;
    padding: 0;
    border: 0;
    background-color: transparent;
    cursor: pointer;
    display: flex;
  }
  
  .hamburger:focus .hamburger__container {
    box-shadow: 0 0 2px 2px #51a7e8;
  }
  
  .hamburger:focus,
  .hamburger__container:focus {
    outline: none;
  }
  
  .hamburger__container {
    display: flex;
    align-items: center;
    position: relative;
    width: 35px;
    height: 30px;
  }
  
  .hamburger__bars {
    position: absolute;
    width: 35px;
    height: 2px;
    background-color: #6f3f2d;
    transition: transform 220ms ease-in-out;
  }
  
  .hamburger__bars::before,
  .hamburger__bars::after {
    display: block;
    position: absolute;
    width: 35px;
    height: 2px;
    background-color: #6f3f2d;
    content: '';
  }
  
  .hamburger__bars::before {
    top: -12px;
    transition: top 100ms 250ms ease-in, transform 220ms ease-in-out;
  }
  
  .hamburger__bars::after {
    bottom: -12px;
    transition: bottom 100ms 250ms ease-in, transform 220ms ease-in-out;
  }
  
  .hamburger--active .hamburger__bars {
    transform: rotate(225deg);
    transition: transform 220ms 120ms ease-in-out;
  }
  
  .hamburger--active .hamburger__bars::before {
    top: 0;
    transition: top 100ms ease-out;
  }
  
  .hamburger--active .hamburger__bars::after {
    bottom: 0;
    transform: rotate(-90deg);
    transition: bottom 100ms ease-out, transform 220ms 120ms ease-in-out;
  }

  .iconContainer {
    display: block;
    margin: 0 10vw 0 20vw;
}

.iconLink {
  margin-left: 3vw;
}

}
