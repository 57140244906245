.footerContainer {
  background-color: #d8b9ac;
  text-align: left; /* Wyrównanie zawartości do lewej */
  font-family: "Andada";
}

.columnsContainer {
  /* justify-content: space-between; */
  /* width: 100%; */
  margin: 0 5vw;
  display: flex;
  margin-bottom: 20px;
  flex-wrap: wrap; /* Ta linia dodaje zawijanie wersów na mniejszych ekranach */
}

.column {
  width: 30%; /* Dwie kolumny na większych ekranach */
  padding: 10px;
  color: #fff;
}

.columnHeader {
  font-size: 1.5em;
  margin: 2vw 0;
  padding-left: 2vw;
}

.contactInfo,
.socialIcon {
  /* display: flex; */
  flex-direction: column;
  align-items: left;
  /* margin: 10px 0; */
  padding-left: 10px; /* Dodane wyrównanie do lewej */
}

.icon {
  color: #f6ece8;
  margin-right: 10px;
  padding-left: 1vw;
}

.contactItem {
  display: flex;
  align-items: center;
  margin-bottom: 1.3vw;
  color: #fff;
  font-weight: normal;
}

.contactItem p {
    margin: 0;
}


.weseleZklasa {
  width: 3em;
}

.divider {
  border: 1px solid #f6ece8;
  margin: 20px 0;
}

.siteAuthor {
  margin-top: 20px;
  text-align: center;
  color: #f6ece8;
}

.siteAuthor p {
  margin: 0;
  padding-bottom: 10px;
}

.socialIcon {
  margin: 0;
  padding-left: 1vw;
}

.socialIcon p {
  margin: 0;
}

.iconWithText {
  display: flex;
  align-items: center;
  margin: 0 0 1.3vw 0;
  padding-left: 10px; /* Dodane wyrównanie do lewej */
  padding-top: 0.1vw;
  color: #fff;
}

.iconWithText p {
  margin: 0;
  font-weight: normal;
  margin-left: 10px; /* Dodatkowy odstęp między ikoną a tekstem */
}

.contactItem a {
  margin: 0; /* Usunięcie marginesu tylko dla linków wewnątrz .contactItem */
}

@media (max-width: 768px) {
  .column {
    width: 100%; /* Jedna kolumna na mniejszych ekranach */
}

  .iconWithText {
    margin-bottom: 4vw;
  }

  .columnHeader {
    margin-top: 5vw;
  }

  .contactItem {
    margin-bottom: 4vw;

  }
}
