/* FAQ.module.css */

/* Styl na większych ekranach */
.faqContainer {
    background-color: #ecebec;
    color: #7b503c;
    padding: 10vw 3vw;
    font-family: 'Andada';
}

.faqTitle {
    font-size: 2.5vw;
    margin-left: 3vw;
    margin-bottom: 3vw;
}

.faqList {
    margin: 2vw 10vw;
    -webkit-box-shadow: 11px -3px 18px -14px rgba(66, 68, 90, 1);
-moz-box-shadow: 11px -3px 18px -14px rgba(66, 68, 90, 1);
box-shadow: 11px -3px 18px -14px rgba(66, 68, 90, 1);
}

.faqItem {
    margin: 1.6vw 0;
}

.faqQuestion {
    background-color: #fff;
    padding: 1.3vw;
    display: flex;
    justify-content: space-between;
    cursor: pointer;
    user-select: none;
    position: relative;
    font-size: 1.6vw;
    /* border-radius: 30px; */
}

.faqAnswer {
    background-color: #fff;
    padding: 1.3vw;
    font-size: 1.6vw;
    border-top: 1px solid #a88c61;;
}

.arrowUp::before {
    content: "▲";
}

.arrowDown::before {
    content: "▼";
}

/* Styl na mniejszych ekranach (telefony komórkowe) */
@media (max-width: 768px) {
    .faqContainer {
        padding: 25vw 3vw;
    }

    .faqTitle {
        font-size: 6vw; /* Dostosuj wielkość czcionki na mniejszych ekranach */
        margin-left: 1vw;
        margin-bottom: 6vw;
    }

    .faqList {
        margin: 2vw 0; /* Usuń lewy margines na mniejszych ekranach */
    }

    .faqItem {
        margin: 4vw 0; /* Zwiększ odstęp między elementami na mniejszych ekranach */
    }

    .faqQuestion {
        font-size: 4vw; /* Dostosuj wielkość czcionki na mniejszych ekranach */
        padding: 3vw;
    }

    .faqAnswer {
        font-size: 4vw; /* Dostosuj wielkość czcionki na mniejszych ekranach */
        padding: 3vw;
    }
}
