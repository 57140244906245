.container {
  margin: 0 5vw 2vw 5vw;
  padding-top: 10vw;
  line-height: 1.5;
  font-size: 16px;
  }

  .container li {
    /* font-size: 1.6vw; */
    line-height: 1.5;
    font-family: Andada;
  }


  .row {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .columnLeft {
    flex: 1;
    font-family: Andada;
    text-align: justify;
    position: relative;
    color: #6f3f2d;
    /* margin-right: 3vw; */
    /* height: 40vw; */
  }

  .columnRight {
    flex: 1;
    font-family: Andada;
    text-align: justify;
    position: relative;
    color: #6f3f2d;
    /* font-size: 1.6vw; */
  }
  
  /* Style dla tekstu */
  .title {
    font-size: 2vw;
    font-weight: bold;
    margin: 2vw 0 1vw 0;
    text-align:left;
  }

  .text .additional {
    /* font-size: 1.6vw; */
    line-height: 1.5;
    /* margin: 2vw 7vw; */
  }
  
  /* Style dla listy */
  .list {
    list-style: disc;
    /* margin-left: 20px; */
  }
  
  /* Style dla obrazków po lewej stronie */
  .imageLeft {
    height: 100%;
    border-radius: 500px;
    /* margin-left: 20px; Dodaje odstęp od lewej strony */
  }
  
  /* Style dla obrazków po prawej stronie */
  .imageRight {
    height: 100%;
    border-radius: 500px;
    /* margin-left: 20px; Dodaje odstęp od lewej strony */
  }

  .columnLeftImg {
    display: flex;
    flex: 1;
    margin: 2vw 0;
    /* padding: 20px; */
    justify-content: center;
    position: relative;
    /* margin-right: 5vw; */
    color: #6f3f2d;
    height: 40vw;
  }

  .columnRightImg {
    height: 40vw;
    flex: 1;
    display: flex;
    /* padding: 20px; */
    justify-content: center;
    position: relative;
    /* margin-left: 5vw; */
    margin: 2vw 0;
    color: #6f3f2d;
  }

  
  /* Separator */
  .separator {
    height: 2px;
    background-color: #a88c61;
    margin: 20px 0;
  }

  .package {
    font-weight: bold;
  }
  

  @media (max-width: 768px) {
    .container {
      margin: 0 4vw; /* Dostosuj marginesy na mniejszych ekranach */
      padding-top: 20vw;
    }

    ul {
      padding: 0;
    }
  
    .container li {
      font-size: 4vw; /* Dostosuj wielkość czcionki na mniejszych ekranach */
      list-style: none;
    }
  
    .row {
      flex-direction: column; 
      /* Zmień układ na kolumnowy na mniejszych ekranach */
    }
  
    .columnLeft,
    .columnRight {
      flex: 1;
      text-align: justify;
      position: relative;
      text-align: center;
      color: #6f3f2d;
      margin: 2vw 0; /* Dostosuj marginesy na mniejszych ekranach */
    }
    
    .title {
      font-size: 5vw; /* Dostosuj wielkość czcionki na mniejszych ekranach */
      text-align: center;
    }
  
    .container p {
      font-size: 4vw; /* Dostosuj wielkość czcionki na mniejszych ekranach */
    }
    
    .text {
      font-size: 4.5vw; /* Dostosuj wielkość czcionki na mniejszych ekranach */
    }
    
    .list {
      list-style: disc;
    }
    
    .imageLeft,
    .imageRight {
      height: 100vw; /* Dostosuj wysokość obrazków */
      border-radius: 10px; /* Dostosuj zaokrąglenie rogów */
      margin: 2vw 0; /* Dostosuj marginesy na mniejszych ekranach */
    }

    .imageLeft {
      display: none;
    }
  
    .columnLeftImg,
    .columnRightImg {
      flex: 1;
      height: 30vw;
      display: flex;
      margin: 2vw 0; /* Dostosuj marginesy na mniejszych ekranach */
      justify-content: center;
      position: relative;
      color: #6f3f2d;
      height: auto; /* Dostosuj wysokość na mniejszych ekranach */
    }
  
    .separator {
      height: 2px;
      background-color: #a88c61;
      margin: 2vw 0; /* Dostosuj marginesy na mniejszych ekranach */
    }
  
    .package {
      font-weight: bold;
    }


  }
