.opinieContainer {
    font-family: Andada;
    margin: 0 3vw;
    font-size: 1.2vw;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    /* width: 100%; */
    color: #6f3f2d;
    justify-content: space-between;
  }

  .opinieList {
    width: 100%;
    display: flex;
    flex-direction: row;
    margin: 2vw 3vw 0 3vw;
  }
  
  .opinia {
    width: 30%;
    line-height: 1.2; 
    height: 20vw;
    margin: 10px;
    overflow-y: auto;
    border: 1px solid #a88c61;
    border-radius: 25px;
    -webkit-box-shadow: 4px 4px 30px -15px rgba(66, 68, 90, 1);
-moz-box-shadow: 4px 4px 30px -15px rgba(66, 68, 90, 1);
box-shadow: 4px 4px 30px -15px rgba(66, 68, 90, 1);
  }
  
  .opiniaSection {
    display: flex;
    flex-direction: column;
    padding: 1vw 1vw;
  }
  
  .pagination {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    margin-bottom: 3vw;
  }
  
  .pagination button {
    background: #d8b9ac;;
    border-radius: 15px;
    color: #fff;
    border: none;
    padding: 5px 10px;
    margin: 0 5px;
    cursor: pointer;
  }
  
  .pagination button:hover {
    background: #6f3f2d;
  }

  .opinieContainer h1 {
    text-align: left;
    margin: 3vw 3vw 0 4vw;
  }



  @media (max-width: 768px) {

  .opinieList {
    flex-direction: column;
    margin: 1vw 1vw 0 1vw;
    align-items: center;
  }
  
  .opinieContainer {
    margin: 3vw 0;
    font-size: 4.1vw;
  }

  .opinia {
    width: 90%;
    height: 67vw;
  }

  .opinieContainer h1 {
    text-align: center;
    margin: 3vw 0;
  }

  .opiniaSection {
    padding: 3vw 4vw;
  }

}