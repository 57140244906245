/* Hero.module.css */

@font-face {
  font-family: 'Afrah';
  src: url('./czcionka1.otf') format('truetype');
}

.hero-container {
  background-color: #f6ece8;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  /* font-family: Andada; */
  margin-bottom: 3vw;
}

.hero-content {
  margin: 20px;
  text-align: left;
  max-width: 50%;
  position: relative;
  order: 1; /* Zamienia kolejność lewej i prawej części na mniejszych ekranach */
}

.title {
  color: #6f3f2d;
  font-size: 7vw;
  /* font-weight: bold; */
  text-align: left; /* Tekst w lewej części */
  margin-top: 17vw;
  margin-left: 5vw;
  font-family: 'Afrah', sans-serif;
  font-weight: normal;
}

.subtitle {
  color: #a88c61;
  font-size: 2.2vw;
  text-align: left; /* Tekst w lewej części */
  margin-left: 5vw;
  font-family: 'Afrah';
  font-weight: bold;
  
}

.hero-image {
  /* max-width: 50%; */
  order: 1; /* Zamienia kolejność lewej i prawej części na mniejszych ekranach */
  position: relative;
}

.heroImage {
  width: auto;
  height: 48vw;
  border-bottom-left-radius: 500px;
  border-bottom-right-radius: 500px;
  margin-right: 10vw;
  
}

.image-button {
  position: absolute; /* Ustawiamy na pozycję absolutną, aby przemieścić przycisk poza kontener */
  left: 38%; /* Wycentrowanie w poziomie względem okna przeglądarki */
  top: 85%; /* Wycentrowanie w pionie względem okna przeglądarki */
  transform: translate(-50%, -50%);
  text-align: center;
  color: #a88c61;
  padding: 10px 20px;
  font-size: 18px;
  cursor: pointer;
  border-radius: 5px;
}

.heroButton {
  /* display: none;  */
  background-color: #fff;
  width: 17vw;
  height: 3vw;
  border-style: none;
  border-radius: 10px;
  color: #a88c61 ;
  font-family: Andada;
  font-size: 1rem;
}

/* Responsywność */

@media (max-width: 768px) {
  .hero-container {
    display: flex;
    flex-direction:column;
    align-items: flex-start;
    justify-content: space-between;
    font-family: Andada;
    margin-bottom: 3vw;
    padding-top: 22vw;
    /* align-items: left; */

  }

  .hero-content {
        margin: 6vw 0;
        text-align: center;
        width: 100%;
        max-width: 100%; 
  }

  .hero-content h1 {
    font-size: 18vw;
    margin: 0 2vw;
  }

  .hero-image {
    width: 100%;
    height: auto;
    border-bottom-left-radius: 500px;
    border-bottom-right-radius: 500px;
    margin: 0;
  }

  .heroImage {
    width: 100%;
    height: auto;
    border-bottom-left-radius: 500px;
    border-bottom-right-radius: 500px;
    margin: 0;
  }

  .image-button {
    position: absolute;
    margin-top: 3vw;
    order: 1;
    top: 90%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .heroButton {
    width: 40vw;
    height: 10vw;
    font-size: 1.3rem;
  }

  .subtitle {
    font-size: 6vw;
    margin: 3vw 2vw;
  }


}
